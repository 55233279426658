<template>
    <div class="foot">
        <div class="body1400" style="padding-top: 66px;">
            <div style="display: flex;text-align: left;" >
                <div  style="width: 400px;text-align: left;" >
                    <img :src="logzyw">
                </div>
                <div class="child1 father" style="">
                    <div class="son" >
                        <div class="foot-head">光伏能源</div>
                        <div style="margin-top: 23px;">
                        <span class="foot-row mgd"
                             @click="goto('/projectcp')">能源产品</span><br/>
                        <span class="foot-row mgd"
                             @click="goto('/hec')">综合能源</span>

                        </div>
                    </div>
                </div>
                <div class="child1 father"  >
                    <div class="son" >
                        <div class="foot-head">智慧科技</div>
                        <div style="margin-top: 23px;">

                            <span class="foot-row mgd"
                                  @click="goto('/zhlamp')">智慧路灯</span><br/>
                            <span class="foot-row mgd"
                                  @click="goto('/gflamp')"> 光伏路灯</span><br/>
                            <span class="foot-row mgd"
                                  @click="goto('/system')"> 智慧平台</span><br/>
                            <span class="foot-row mgd"
                                  @click="goto('/zhscheme')"> 应用方案</span>
                        </div>
                    </div>
                </div>
                <div class="child1 father"  >
                    <div class="son" >
                        <div class="foot-head">能源电器</div>
                        <div style="margin-top: 23px;">
                        <span class="foot-row mgd"
                              @click="gowinds('http://mall.hiten.cn/ebaby')">辉腾电宝宝</span><br/>
                            <span class="foot-row mgd"
                                  @click="gowinds('http://mall.hiten.cn/cooker')">辉腾电磁炉</span>
                        </div>
                    </div>
                </div>
                <div class="child1 father" style="width: 200px;"  >
                    <div class="son" >
                        <div class="foot-head">关于辉腾</div>
                        <div style="margin-top: 23px;">
                        <span class="foot-row mgd"
                              @click="goto('/about')">公司介绍</span><br/>
                            <span class="foot-row mgd"
                                  @click="goto('/qywh')">企业文化</span><br/>
                            <span class="foot-row mgd"
                                  @click="goto('/fzls')"> 发展历程</span><br/>
                            <span class="foot-row mgd"
                                  @click="goto('/jstd')"> 技术团队</span><br/>
                            <span class="foot-row mgd"
                                  @click="goto('/zzry')"> 资质荣誉</span><br/>
                            <span class="foot-row mgd"
                                  @click="goto('/shzz')"> 社会责任</span>
                        </div>
                    </div>
                </div>
                <div class="child1 father" style="width:80px;"  >
                    <div class="son" >
                        <div class="foot-head">帮助中心</div>
                        <div style="margin-top: 23px;">
                            <span class="foot-row mgd"
                                  @click="service()">联系我们</span><br/>
                            <span class="foot-row mgd"
                                  @click="goto('/jszc')"> 技术支持</span><br/>
                            <span class="foot-row mgd"
                                  @click="goto('/rczp')"> 招贤纳士</span>
                        </div>
                    </div>
                </div>
            </div>
            <div style="margin-top: 30px;border-bottom: 1px solid #cbcbcb;"> </div>
        </div>
        <div class="body1400" style="height: 310px;">
            <div style="display: flex;text-align: left;margin-top: 40px;color: #000000" >
                <div  style="width: 310px;text-align: left;" >
                   <div><img :src="tely"></div>
                    <div style="font-size: 16px;line-height: 30px;margin-top: 10px;"> 全球服务电话</div>
                    <div style="font-size: 30px;font-weight: bold;line-height: 40px;"> 023-6806 5555</div>
                    <div style="font-size: 16px;line-height: 30px;"> 免费热线</div>
                </div>
                <div class="child1 father" style="width: 290px;">
                    <div><img :src="tely"></div>
                    <div style="font-size: 16px;line-height: 30px;margin-top: 10px;"> 咨询热线</div>
                    <div style="font-size: 30px;font-weight: bold;line-height: 40px;"> 400-004-2004</div>
                    <div style="font-size: 16px;line-height: 30px;"> 售前咨询、售后服务</div>


                </div>
                <div class="child1 father"  style="width: 290px;" >
                    <div><img :src="posty"></div>
                    <div style="font-size: 16px;line-height: 30px;margin-top: 10px;"> 公司地址</div>
                    <div style="font-size: 16px;line-height: 30px;"> 中国 • 重庆 • 九龙坡区留学生创业园</div>
                    <div style="font-size: 16px;line-height: 30px;"> B2栋7-1</div>
                </div>
                <div class="child1 father"  style="width: 200px;" >
                </div>
                <div class="child1 father" style="width: 180px;"  >
                    <div style="text-align: right"><img :src="hiten"></div>
                </div>
                <div class="child1 father" style="width:180px;"  >
                    <div style="text-align: right"><img :src="mall"></div>
                </div>
            </div>
        </div>
        <div class="gafoot">
            <div class="bottom">©2020 重庆辉腾能源股份有限公司
                <a href="http://beian.miit.gov.cn" target="_blank" rel="nofollow"
                   style="margin-left: 10px;">
                    渝ICP备18013736号-1 </a>
               <img :src="ga" class="mr10">
                <a href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=50010702503013" target="_blank"
                   rel="nofollow">
                    渝公网安备 50010702503013号
                </a>
            </div>
        </div>

    </div>
</template>

<script>
    export default {
        name: 'myfoot',
        props: {
            msg: String
        },
        data() {
            return {
                showCode:false,
                ga: require('../assets/ga.png'),
                logzyw:require('../assets/logzyw.png'),
                tely:require('../assets/tely.png'),
                posty:require('../assets/posty.png'),
                hiten:require('../assets/hiten.png'),
                mall:require('../assets/mall.png'),
                /*     tbdy: require('../assets/tbdy.png'),
                   tbwx: require('../assets/tbwx.png'),
                   tbwb: require('../assets/tbwb.png'),
                   tbxhs: require('../assets/tbxhs.png'),*/
            }
        },
        mounted() {
            /*document.addEventListener('click', e => {
                console.log(e.target.className)
                console.log(e.target.className.indexOf('mgd')!=-1)
                if ((e.target.className.indexOf('mgd')==-1)
                    & (e.target.className != 'showWindow')
                    & (e.target.parentElement.className != 'showWindow')
                    & (e.target.parentElement.parentElement.className != 'showWindow')
                    & (e.target.parentElement.parentElement.parentElement.className != 'showWindow')
                ) {
                    this.showCode = false //这句话的意思是点击其他区域关闭（也可以根据自己需求写触发事件）
                }
            })*/
        },
        methods: {
            goto(url) {
                //this.$parent.service(false)
                this.$router.push({ path: url });
            },
            showTel(){
                this.showCode=(!this.showCode);
            },
            gowinds(url){
                window.open(url)
            },
            service(){
                //this.showService=false
                //this.showseach=(!this.showseach)
                //console.info(1)
                this.$parent.service()
            },
        },
    }
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    .foot {
        background-color: #f6f6f6;
        text-align: center;
        color: red;
        /*border: 1px solid red;*/
    }
    .table {
        display: table;
        width: 100%;}
    .father {
        width: 235px;
        justify-content: flex-end;
        vertical-align:top;
        text-align: left;
        /*border: 1px solid red;*/
    }
    .son {
        color: rgba(0,0,0,1);
        text-align:left ;
        margin:auto;
        /*border: 1px solid green;*/
    }
    .foot-head {
        font-weight: bold;
        font-size: 16px;
        margin-bottom: 15px;
        padding-left: 5px;
    }
    .foot-row {
        color: rgba(0,0,0,1);
        font-size: 16px;
        line-height: 28px;
        padding-left: 5px;
        /*border: 1px solid red;*/
    }

    .bottom {
        text-align: center;
        height: 34px;
        line-height: 34px;
        font-size: 12px;
    }
    .mr10 {
        margin-left: 10px;
        margin-right: 10px;
        vertical-align: middle;
    }

    .myselect {
        appearance: none;
        width: 125px;
        height: 29px;
        margin-top: 0px;
        margin-left: 0px;
        font-size: 12px;
        border: solid 0px #262626;
        /*background: no-repeat scroll center transparent;
        background-color: rgba(0, 0, 0, 0.3);
        background-image: url("../assets/jtr.png");*/

       /* background: rgba(0, 0, 0, 0.3) url(../assets/jtr.png) no-repeat right 0px;*/

        background-position-x: 100px;
        border-radius: 0px;
        padding-left: 5px;
        color: rgba(255, 255, 255, 0.6);
    }

    .sel_wrap {
        height: 29px;
        width: 125px;
        /*background: rgba(0, 0, 0, 0.3) url(../assets/jtr.png) no-repeat right 5px top 6px;*/
        color: rgba(255, 255, 255, 0.6);
        font-size: 12px;
        border: 0px solid #E4E4E4;
        cursor: pointer;
        position: relative;
        _filter: alpha(opacity=0);

    }

    .sel_wrap label {
        padding-left: 5px;
        font-size: 12px;
        z-index: 2;
        color: rgba(255, 255, 255, 0.6);
        line-height: 29px;
        height: 29px;
        display: block;
    }

    .sel_wrap .select {
        width: 100%;
        height: 29px;
        line-height: 29px;
        z-index: 4;
        position: absolute;
        top: 0;
        left: 0;
        margin: 0;
        padding: 0;
        opacity: 0;
        *margin-top: 10px;
        filter: alpha(opacity=0);
        cursor: pointer;
        font-size: 12px;
    }
    .mgd {
        cursor:pointer;
        padding: 0 5px;
    }
    .mgd:hover {
        background-color: rgba(203,203,203,1);
        color: rgba(24,24,24,0.9);
        /*border-radius: 5px;*/
    }
    .gwlink{
        cursor:pointer;
    }
    .gwlink:hover {
        border-bottom:1px solid  rgba(255,255,255,0.6);
        padding-bottom: 2px;

    }
    .showWindow{
        width: 350px;
        /*height: 250px;*/
        position: fixed;
        background-color: white;
        bottom:300px;
        padding: 20px;
        left:calc((100% - 350px) / 2);
        z-index: 99999;
        overflow-x: hidden;
        overflow-y: hidden;
        border: 1px dashed rgba(24,24,24,0.6) ;
    }
    a{
        color: #ffffff;
    }
    .gafoot{
        background-color: #282828;
        color: #ffffff;
    }
</style>
