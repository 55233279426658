// import store from "@/store";

let timeoutId
const notice = {
  text: '',
  status: '',
  setNote (text = '', status = 'pending') {
    if (text instanceof Error) {
      status = 'fail'
      text = text.message
      //Raven.captureException(text) // 错误上报
    } else if (status === 'pending' && typeof text === 'object') {
      status = text.messagebz == 100 ? 'succeed' : 'fail'
      text ='网络异常'
    } else if (status === 'pending' && text !== '') {
      text = '<i class="spinner loading icon inverted"></i>' + text
    }
    this.text = text
    this.status = status

    clearTimeout(timeoutId)
    // 几秒后自动隐藏
    if (status !== 'pending') {
      timeoutId = setTimeout(() => {
        this.setNote()
      }, 10000)
    }
  },
}
window.NOTE = notice.setNote.bind(notice)
export default notice
