/*import './notice'*/

//import 'expose-loader?utils!lib/utils'
/*import 'expose-loader?utils!./utils'*/


import Vue from 'vue'

import numseg from "@/filter/numseg";
Vue.filter('numseg',numseg);

import mmt from "@/filter/mmt";
Vue.filter('mmt',mmt);



Array.prototype.keysum = function (key, len = 0) {
    let total = 0
    this.forEach(v => {
        if (v[key]) {
            total += parseFloat(v[key])
        }
    })
    return round(total, len)
}
Array.prototype.keysumColc = function (key1,key2, len = 0) {
    let total = 0
    this.forEach(v => {
        if (v[key1] && v[key2]) {
            total += parseFloat(v[key1])*parseFloat(v[key2])
        }
    })
    return round(total, len)
}
// PHP round
export function round (num, len = 0) {
    return Math.round(num * Math.pow(10, len)) / Math.pow(10, len)
}



