/*import  './request/global'*/
/*import  './request/api'*/
import './css/base.css'
/*import './lib/utils'*/
/*import './lib/init'*/
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'

import "./lib/base"
import Vue from 'vue'
import App from './App.vue'
import iView from 'iview'

// import store  from 'store'
// window.$GG = Vue.prototype.$GG = store

import notice from './store/modules/notice'

window.NOTE = Vue.prototype.notice = notice.setNote.bind(notice)


Vue.use(iView)
Vue.use(ElementUI)
Vue.config.productionTip = false;
Vue.config.debug = true;//开启错误提示

import VueRouter from 'vue-router'

Vue.use(VueRouter)
const router = new VueRouter({
    mode: 'history',  //  hash  history
    routes: [
        {path:'/',component:()=>import('./page/main.vue'),meta:{title:'重庆辉腾能源股份有限公司',keepAlive:true,}},
        {path:'/about',component:()=>import('./page/about.vue'),meta:{title:'关于辉腾.辉腾能源',keepAlive:true,}},
        {path:'/news',component:()=>import('./page/news.vue'),meta:{title:'新闻动态.辉腾能源',keepAlive:true,}},
        {path:'/new',component:()=>import('./page/new.vue'),meta:{title:'新闻动态.辉腾能源',keepAlive:true,}},
        {path:'/projectcp',component:()=>import('./page/photovoltaic.vue'),meta:{title:'光伏能源.辉腾能源',keepAlive:true,}},
        //{path:'/projectfa',component:()=>import('./page/main.vue'),meta:{title:'智慧科技.辉腾能源',keepAlive:true,}},
        {path:'/civilcp',component:()=>import('./page/main.vue'),meta:{title:'能源电器.辉腾能源',keepAlive:true,}},
        {path:'/joinin',component:()=>import('./page/joinin.vue'),meta:{title:'招商加盟.辉腾能源',keepAlive:true,}},
         {path:'/yyc',component:()=>import('./page/main.vue'),meta:{title:'应用层.辉腾能源',keepAlive:true,}},
         {path:'/ptc',component:()=>import('./page/main.vue'),meta:{title:'平台层.辉腾能源',keepAlive:true,}},
         {path:'/sbc',component:()=>import('./page/main.vue'),meta:{title:'设备层.辉腾能源',keepAlive:true,}},
         {path:'/zngf',component:()=>import('./page/main.vue'),meta:{title:'智能光伏.辉腾能源',keepAlive:true,}},
         {path:'/zhzm',component:()=>import('./page/main.vue'),meta:{title:'智慧照明.辉腾能源',keepAlive:true,}},
         {path:'/whld',component:()=>import('./page/main.vue'),meta:{title:'文化路灯.辉腾能源',keepAlive:true,}},
         {path:'/znsc',component:()=>import('./page/main.vue'),meta:{title:'智能杀虫.辉腾能源',keepAlive:true,}},
         {path:'/nygl',component:()=>import('./page/main.vue'),meta:{title:'能源管理.辉腾能源',keepAlive:true,}},
         {path:'/othercp',component:()=>import('./page/main.vue'),meta:{title:'其他能源产品.辉腾能源',keepAlive:true,}},
         {path:'/ebaby',component:()=>import('./page/main.vue'),meta:{title:'辉腾电宝宝.辉腾能源',keepAlive:true,}},
          {path:'/cooker',component:()=>import('./page/main.vue'),meta:{title:'辉腾电磁炉.辉腾能源',keepAlive:true,}},
          {path:'/bxledd',component:()=>import('./page/main.vue'),meta:{title:'便携式LED灯.辉腾能源',keepAlive:true,}},
         {path:'/about',component:()=>import('./page/about.vue'),meta:{title:'公司介绍.辉腾能源',keepAlive:true,}},
         {path:'/qywh',component:()=>import('./page/qywh.vue'),meta:{title:'企业文化.辉腾能源',keepAlive:true,}},
         {path:'/fzls',component:()=>import('./page/fzls.vue'),meta:{title:'发展历程.辉腾能源',keepAlive:true,}},
         {path:'/jstd',component:()=>import('./page/jstd.vue'),meta:{title:'技术团队.辉腾能源',keepAlive:true,}},
         {path:'/zzry',component:()=>import('./page/zzry.vue'),meta:{title:'资质荣誉.辉腾能源',keepAlive:true,}},
         {path:'/shzz',component:()=>import('./page/shzz.vue'),meta:{title:'社会责任.辉腾能源',keepAlive:true,}},
         {path:'/tel',component:()=>import('./page/main.vue'),meta:{title:'联系我们.辉腾能源',keepAlive:true,}},
         {path:'/jszc',component:()=>import('./page/main.vue'),meta:{title:'技术支持.辉腾能源',keepAlive:true,}},

        {path:'/scheme',component:()=>import('./page/scheme.vue'),meta:{title:'技术方案.辉腾能源',keepAlive:true,}},
        {path:'/gflamp',component:()=>import('./page/gflamp.vue'),meta:{title:'光伏路灯.辉腾能源',keepAlive:true,}},
        {path:'/zhlamp',component:()=>import('./page/zhlamp.vue'),meta:{title:'智慧路灯.辉腾能源',keepAlive:true,}},
        {path:'/zhscheme',component:()=>import('./page/zhscheme.vue'),meta:{title:'智慧照明方案.辉腾能源',keepAlive:true,}},

        {path:'/hec',component:()=>import('./page/hec.vue'),meta:{title:'HEC综合能源.辉腾能源',keepAlive:true,}},
        {path:'/sunroom',component:()=>import('./page/sunroom.vue'),meta:{title:'光伏阳光房.辉腾能源',keepAlive:true,}},
        {path:'/building',component:()=>import('./page/building.vue'),meta:{title:'光伏建筑一体化.辉腾能源',keepAlive:true,}},
        {path:'/factory',component:()=>import('./page/factory.vue'),meta:{title:'光伏钢构厂房.辉腾能源',keepAlive:true,}},
        {path:'/farm',component:()=>import('./page/farm.vue'),meta:{title:'光伏农业.辉腾能源',keepAlive:true,}},
        {path:'/wisdomld',component:()=>import('./page/wisdomld.vue'),meta:{title:'智慧路灯.辉腾能源',keepAlive:true,}},
        {path:'/wisdomsz',component:()=>import('./page/wisdomsz.vue'),meta:{title:'智慧市政.辉腾能源',keepAlive:true,}},
        {path:'/system',component:()=>import('./page/system.vue'),meta:{title:'智慧平台.辉腾能源',keepAlive:true,},
            redirect: '/system/hoc',
            children: [
                {path:'hoc',component:()=>import('./components/hoc.vue'),meta:{title:'HOC.辉腾能源',keepAlive:true,}},
                {path:'hic',component:()=>import('./components/hic.vue'),meta:{title:'HIC.辉腾能源',keepAlive:true,}},
                {path:'hsc',component:()=>import('./components/hsc.vue'),meta:{title:'HSC.辉腾能源',keepAlive:true,}},
                {path:'hpc',component:()=>import('./components/hpc.vue'),meta:{title:'HPC.辉腾能源',keepAlive:true,}},
            ],
        },
        {path:'/rczp',component:()=>import('./page/rczp.vue'),meta:{title:'人才理念.辉腾能源',keepAlive:true,},
            redirect: '/rczp/zwsq',
            children: [
                {path:'rcln',component:()=>import('./components/rcln.vue'),meta:{title:'人才理念.辉腾能源',keepAlive:true,}},
                {path:'zwsq',component:()=>import('./components/zwsq.vue'),meta:{title:'人才招聘.辉腾能源',keepAlive:true,}},
            ],
        },

    ]
})

router.beforeEach((to, from, next) => {
    window.document.title = to.meta.title == undefined ? '辉腾能源' : to.meta.title
    next();

})

/*
new Vue({
    data: store, //观察store
    render: h => h(App),
}).$mount('#app')
*/

new Vue({
    router,
    el: '#app',
    //data: store, //观察store
    render: h => h(App),
}) //.$mount('#app')

/*
import 'video.js/dist/video-js.css'
import { videoPlayer } from 'vue-video-player'
Vue.components("videoPlayer",videoPlayer)
*/


