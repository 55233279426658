<template>
    <div id="app" >

                 <router-view>
                </router-view>
                <myfoot/>

    <div v-if="showService"  class="service" @click="service()">
        <div style="font-size: 14px;color: rgba(0,0,0,0.6);margin-top: 40px;">CUSTOMER SERVICE</div>
        <div style="font-size: 24px;font-weight:bold ;color: #181818;margin-top: 18px;">客户服务</div>
        <div style="height:1px;background-color: rgba(0,0,0,0.1);margin-top: 22px;"></div>
        <div class="boxnobk" style="color: rgb(24,24,24);margin-top: 20px;">
            <div class="col1" style="width: 472px;text-align: left;">
                <div style="font-size: 16px;">在线客服</div>
                <div style="font-size: 14px;color: rgba(24,24,24,0.8);line-height: 20px;margin-top: 0px;line-height: 14px;">
                    <ul style="margin-left: -26px; "> <li>我们的在线客服从8:30-18:00（节假日除外）提供咨询服务。</li>
                    </ul>
                </div>


                <div style="font-size: 16px;margin-top: 50px;">客服咨询热线</div>
                <div style="font-size: 14px;color: rgba(24,24,24,0.8);line-height: 20px;margin-top: 0px;line-height: 14px;line-height: 24px;">
                    <ul style="margin-left: -26px; "> <li>我们的在线客服从8:30-18:00（节假日除外）提供线上购物，<br>
                        线下门店服务及商品资讯、售后相关的资讯服务。</li>
                    </ul>
                </div>
                <div style="font-size: 14px;color: rgba(24,24,24,0.8);line-height: 20px;margin-top: 20px;line-height: 24px;">
                    {{mynbsp}}来电请致：400 004 2004<br>
                    {{mynbsp}}{{mynbsp}}{{mynbsp}}{{mynbsp}}{{mynbsp}}{{mynbsp}}023-6806 5555</div>


                <div style="font-size: 16px;margin-top: 50px;">尊享门店服务</div>
                <div style="font-size: 14px;color: rgba(24,24,24,0.8);line-height: 20px;margin-top: 0px;line-height: 14px;line-height: 24px;">
                    <ul style="margin-left: -26px; "> <li>您可通过下方查询附近门店，前往门店咨询产品相关信息，<br>
                        体验产品功能。</li>
                    </ul>
                </div>

            </div>
            <div class="col1"  style="width: 184px;text-align: left;">
                <div style="font-size: 16px;">关注我们</div>
                <div style="font-size: 12px;color: rgba(24,24,24,0.8);line-height: 20px;margin-top: 8px;">
                    <ul style="margin-left: -26px; "> <li>邀请您关注电宝宝与辉腾能源<br>
                        官方微信，获取相关资讯！</li>
                    </ul>

                </div>
                <div style="margin-top: 16px;">
                    <img :src="js_ewcode">
                </div>
            </div>
        </div>
    </div>
    </div>
</template>
<script>

    import myfoot from './components/foot.vue'
    export default {
        name: 'App',
        data() {
            return {
                param: {
                    pageindex: 0,
                    menuClickleft: (5-1)*120+30,
                    menuClickShow:'',
                },
                indexground: {
                    'background-image': 'url(' + require('assets/index.jpg') + ') ',
                    'background-repeat': 'no-repeat',
                    'background-position': '0 0',
                    'min-height': '640px',
                    'width': '714px',
                    'margin-left': '20px',
                },

                mynbsp:"\u3000",
                js_ewcode: require('./assets/hitenwxcode.jpg'),
                showService:false,
            }
        },
        components: {
            myfoot,
        },
        methods: {
            service(){
                //this.showseach=false
                this.showService = (!this.showService)

            },
        },
    }
</script>

<style>

    #app {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-align: center;
        margin: 0px;
       /* min-height: 6293px ;*/
        /*background-color: #e08787;*/
    }

    body {
        /*background-image: url("/img/index.e821a05e.jpg");*/
        min-width: 1660px;
        /*font-family: Avenir, Helvetica, Arial, sans-serif;*/
        font-family: "Hiragino Sans GB", "WenQuanYi Micro Hei", "Microsoft YaHei", Tahoma, SimSun, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
        padding: 0; /*去除bai内边距*/
        border: 0; /*去除边框*/
        margin: 0; /*去除外边距*/
        /*width: 1024px;*/
        text-align: center;
        background-color: white;
        color: #202020;

    }

    a {
        text-decoration: none
    }

    .service{
        position: fixed;
        background-color: white;
        /*width: 776px;*/
        /*height: 698px;*/
        height: 550px;
        top:130px;
        left:calc((100% - 776px) / 2);
        padding-left:60px;
        padding-right: 60px;
        z-index: 99999;
        border: 1px dashed rgba(24,24,24,0.6) ;
    }

</style>
